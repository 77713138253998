import React from "react"

import Layout from '../components/layout'
import Head from '../components/head'

const ContactPage = () => {
  return (
  <Layout>
    <Head title="Contact" />
    <img id="contactimg" src={`IMG_20190904_115500.png`}></img>
    <p id="emailAddressContact">gbotond92@gmail.com</p>
    <p>+4 0740 458 446</p>
    <form
        action="/"
        method="post"
        action-xhr="/"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="contact"
    >
        <input type="hidden" name="form-name" value="contact"/>
        <input type="email" name="e-mail" placeholder="Your E-mail address"/><br/>    
        <br></br>
        <textarea name="message" placeholder="Message" required></textarea><br/><br/>
        <button variant="primary" type="submit">Send message</button>
        <br></br>
        <br></br>
    </form>
    <div id="emptyContactDiv"></div>
  </Layout>
  )
}
export default ContactPage